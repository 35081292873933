import React from 'react';
import FormToolTip from 'components/tooltip/FormToolTip';

interface FormLabelProps {
  title: string;
  description?: string | null;
  className?: string;
  tooltip?: string;
}

const Section: React.FC<FormLabelProps> = ({ title, description, className, tooltip }) => (
  <div className={`tw-relative tw-text-sm tw-mb-5 tw-mt-2.5 ${className}`}>
    {title && (
      <div className='tw-flex tw-gap-2 tw-items-center'>
        <div className='f-20-500 tw-text-TEXT_GRAY_2'>{title}</div>
        {!!tooltip && <FormToolTip tooltip={tooltip} />}
      </div>
    )}
    {description && <div className='tw-mt-[5px] tw-text-sm tw-text-TEXT_GRAY_4'>{description}</div>}
  </div>
);

export default Section;
