import { FC, memo } from 'react';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { defaultFn } from 'destiny/dist/constants';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { defaultFnType } from 'types';
import FormToolTip from 'components/tooltip/FormToolTip';
import styleClasses from 'styles/input.module.css';

type InputProps = {
  id: string;
  name?: string;
  tooltip?: string;
  label?: string;
  value?: any;
  type?: string;
  placeHolder?: string;
  className?: string;
  customInputClassName?: string;
  error?: string;
  maxLength?: number;
  isDisabled?: boolean;
  style?: React.CSSProperties;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  labelClassName?: string;
  errClassName?: string;
  errBorderClassName?: string;
  showErrIcon?: boolean;
  autoFocus?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  linkGeneration?: boolean;
  onGenerateLink?: defaultFnType;
  max?: string | number;
};

const CustomInput: FC<InputProps> = ({
  id,
  name,
  label,
  tooltip,
  value,
  type,
  placeHolder,
  className,
  customInputClassName = null,
  maxLength,
  error,
  onChange,
  onKeyPress,
  onBlur,
  isDisabled,
  style,
  onClear,
  labelClassName = '',
  errClassName = '',
  errBorderClassName = '',
  showErrIcon = false,
  autoFocus = false,
  inputRef,
  linkGeneration = false,
  onGenerateLink = defaultFn,
  max,
}) => (
  <div className='tw-w-full'>
    {!!label && (
      <FormToolTip
        tooltip={tooltip}
        label={label}
        labelClassName={labelClassName}
        linkGeneration={linkGeneration}
        onGenerateLink={onGenerateLink}
      />
    )}

    <div className={`tw-relative ${type === 'date' && !value ? styleClasses.inputWrapper : ''}`}>
      {value?.toString().length > 0 && !!onClear && (
        <div
          className='tw-absolute tw-h-full tw-w-8 tw-right-[2px] tw-top-0 tw-flex tw-items-center tw-justify-center tw-cursor-pointer'
          role='presentation'
          onClick={onClear}
        >
          <SvgSpriteLoader id='x-close' iconCategory={ICON_SPRITE_TYPES.GENERAL} width={18} height={18} />
        </div>
      )}
      <input
        type={type}
        id={id}
        name={name}
        autoFocus={autoFocus}
        className={`tw-w-full ${className} ${
          customInputClassName
            ? customInputClassName
            : 'tw-h-[59px] 2xl_custom:tw-h-12 tw-px-6 tw-bg-BASE_SECONDARY tw-text-TEXT_GRAY_1 tw-rounded tw-border tw-disabled:opacity-60  disabled:tw-bg-BASE_PRIMARY disabled:tw-cursor-not-allowed  focus:tw-border-ZAMP_PRIMARY'
        } ${error ? errBorderClassName || 'tw-border-ERROR_RED' : 'tw-border-DIVIDER_GRAY'}`}
        style={style}
        maxLength={maxLength}
        placeholder={placeHolder ?? 'Type here'}
        onChange={onChange}
        onBlur={onBlur}
        onKeyUp={onKeyPress}
        min={0}
        value={value}
        disabled={isDisabled}
        ref={inputRef}
        autoComplete='off'
        max={max}
      />
    </div>
    {!!error && (
      <div
        className={`tw-h-min-4 tw-h-fit tw-mt-2 2xl_custom:tw-mt-1 tw-px-1 tw-flex tw-items-center tw-gap-1 ${
          errClassName || 'tw-text-ERROR_RED tw-text-sm'
        }`}
      >
        {showErrIcon && (
          <SvgSpriteLoader
            id='alert-triangle'
            iconCategory={ICON_SPRITE_TYPES.ALERTS_AND_FEEDBACK}
            width={12}
            height={12}
          />
        )}
        {error}
      </div>
    )}
  </div>
);

export default memo(CustomInput);
