import { MapAny } from 'types';
import { IdempotencyHeaderType } from 'types/api';

export enum SHAREABLE_FORM_TYPES {
  SSN = 'ssn',
}

export enum FIELD_TYPES {
  SSN = 'ssn',
  TAX_IDENTIFICATION_DOCUMENT = 'tax_identification_document',
}

export interface GetShareableFormResponse {
  fields: any;
  related_flow: string;
  status: string;
  id: string;
  form_json_data: MapAny;
  meta_data: string;
}

export interface CreateShareableFormField {
  key: string;
  type: string;
  display_name?: string;
  is_required?: string;
  validation_rules?: string;
  meta_data?: string;
}

export interface CreateShareableFormResponse {
  form_url: string;
  form_id: string;
}

export interface CreateShareableFormFlowParams {
  entity_id: string;
  related_flow: string;
  meta_data: string;
}

export interface CreateShareableFormRequestData extends CreateShareableFormFlowParams {
  fields: FIELD_TYPES[];
  receiver_email: string;
  receiver_name?: string;
}

export interface CreateShareableFormRequest extends IdempotencyHeaderType {
  data: CreateShareableFormRequestData;
}

export interface UpdateShareableFormRequest {
  uid: string;
  data: { inputs?: MapAny; form_json_data_string?: string };
}

export interface GenerateSignedUrlPreloginRequest {
  id: string;
  file_extension: string;
}

export enum SHAREABLE_FORM_RELATED_FLOW {
  WHITELIST_KYC = 'WHITELIST_KYC',
  KYB = 'KYB',
}
