export const enum MIME {
  CSV = 'text/csv',
  PDF = 'application/pdf',
}

export const enum INPUT_FILE_FORMATS {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  DOCX = '.docx',
  PDF = '.pdf',
  BMP = '.bmp',
  CSV = '.csv',
  MP4 = 'video/mp4',
  MOV = 'video/quicktime',
  AVI = 'video/x-msvideo',
  WMV = 'video/x-ms-wmv',
  FLV = 'video/x-flv',
  WEBM = 'video/webm',
}

export const VIDEO_FILE_FORMATS = `${INPUT_FILE_FORMATS.MP4}, ${INPUT_FILE_FORMATS.MOV}, ${INPUT_FILE_FORMATS.AVI}, ${INPUT_FILE_FORMATS.WMV}, ${INPUT_FILE_FORMATS.FLV}, ${INPUT_FILE_FORMATS.WEBM}`;
