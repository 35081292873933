import { CSSProperties, FC } from 'react';
import { Button } from 'destiny/dist/components/molecules/button';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { trackMixpanel } from 'utils/mixpanel';

type CopiedTooltipProps = {
  style?: CSSProperties;
  show: boolean;
  className?: string;
  buttonId?: string;
  wrapperOverrideClassName?: string;
};

const CopiedTooltip: FC<CopiedTooltipProps> = ({
  style = {},
  show = false,
  className = '',
  buttonId = 'COPY_CONTENT_BUTTON',
  wrapperOverrideClassName = '!tw-rounded-[5px] !tw-py-1 !tw-px-6 !tw-h-6 !tw-top-14',
}) => (
  <>
    {show && (
      <Button
        buttonProps={{
          wrapperClass: `tw-absolute tw-flex ${wrapperOverrideClassName} ${className}`,
          style: style,
          eventCallback: trackMixpanel,
          id: buttonId,
        }}
      >
        <div className='tw-flex f-12-300'>
          <SvgSpriteLoader
            id='check'
            className='tw-mr-1 tw-min-w-[15px]'
            iconCategory={ICON_SPRITE_TYPES.GENERAL}
            width={15}
            height={15}
          />
          Copied!
        </div>
      </Button>
    )}
  </>
);

export default CopiedTooltip;
