import { FC, memo } from 'react';
import { SupporterInfo } from 'destiny/dist/components/molecules/supporterInfo';
import { SupporterInfoProps } from 'destiny/dist/types';

type InputProps = {
  id: string;
  name?: string;
  tooltip?: string;
  label?: string;
  value?: any;
  type?: string;
  placeHolder?: string;
  className?: string;
  error?: string;
  maxLength?: number;
  isDisabled?: boolean;
  style?: React.CSSProperties;
  onChange: (evt: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyPress?: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (evt: React.FocusEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  autoFocus?: boolean;
  errBorderClassName?: string;
  errClassName?: string;
  showErrIcon?: boolean;
  textAreaStyle?: string;
  showSupporterInfo?: boolean;
  supporterInfoProps?: SupporterInfoProps;
};

const Textarea: FC<InputProps> = ({
  id,
  name,
  value,
  placeHolder = 'Type here',
  className,
  textAreaStyle = 'tw-h-[116px] tw-p-4 tw-bg-BASE_SECONDARY tw-text-TEXT_GRAY_1 tw-rounded tw-disabled:opacity-60 focus:tw-border-ZAMP_PRIMARY',
  onChange,
  autoFocus = false,
  error,
  errBorderClassName,
  showSupporterInfo,
  supporterInfoProps,
}) => (
  <div className='tw-w-full'>
    <textarea
      id={id}
      name={name}
      className={`tw-w-full ${textAreaStyle} tw-outline-none tw-border ${className} ${
        error ? errBorderClassName || 'tw-border-ERROR_RED' : 'tw-border-DIVIDER_GRAY'
      }`}
      placeholder={placeHolder}
      onChange={onChange}
      value={value}
      autoFocus={autoFocus}
    />
    {showSupporterInfo && <SupporterInfo {...supporterInfoProps} />}
  </div>
);

export default memo(Textarea);
