import { ROMA_LOGO_GREEN, ZAMP_LOGO } from 'constants/icons';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import Image from 'next/image';
import { isRomaDashboard } from 'utils/common';

interface TopBarType {
  onClose?: () => void;
  overrideClassName?: string;
  logoClassName?: string;
  customLogo?: string;
  customCloseIcon?: any;
  className?: string;
}

const OverlayTopBarV2: React.FC<TopBarType> = ({
  onClose,
  overrideClassName = 'tw-h-[52px] tw-px-5 tw-border-b tw-border-GRAY_1 tw-flex tw-items-center tw-justify-between tw-bg-white tw-z-10',
  logoClassName = null,
  customLogo = null,
  customCloseIcon = null,
  className = '',
}) => {
  const isRoma = isRomaDashboard();

  return (
    <div className={`${overrideClassName} ${className}`}>
      <div>
        <Image
          alt='zamp logo'
          className={`${logoClassName ?? 'tw-w-[66px]'}`}
          src={customLogo ?? isRoma ? ROMA_LOGO_GREEN : ZAMP_LOGO}
          width={66}
          height={16}
        />
      </div>

      {!!onClose &&
        (customCloseIcon ? (
          <>{customCloseIcon}</>
        ) : (
          <SvgSpriteLoader
            id='x-close'
            iconCategory={ICON_SPRITE_TYPES.GENERAL}
            className='tw-cursor-pointer'
            color={COLORS.TEXT_PRIMARY}
            onClick={onClose}
            width={20}
            height={20}
          />
        ))}
    </div>
  );
};

export default OverlayTopBarV2;
