interface UnorderedListType {
  title: string;
  list: Array<string> | null;
  className?: string;
}

const UnorderedList: React.FC<UnorderedListType> = ({ title, list, className = '' }) => {
  return (
    <div className={`tw-relative tw-text-sm tw-mb-5 tw-mt-2.5 ${className}`}>
      <div className='tw-text-TEXT_GRAY_1 tw-text-base'>{title}</div>
      <ul>
        {list?.map((item: string) => (
          <li className='tw-text-TEXT_GRAY_2 tw-text-sm tw-mt-[5px]' key={item}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UnorderedList;
