import React from 'react';
import FormToolTip from 'components/tooltip/FormToolTip';

interface RangeSliderProps {
  current: string;
  label: string;
  tooltip?: string;
  min: number | null;
  max: number | null;
  onChange: (value: string) => void;
  id?: string;
}

const RangeSlider: React.FC<RangeSliderProps> = ({ current, label, tooltip, min, max, onChange, id }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (parseFloat(e.target.value || '0') > 100) return;

    const [decimal, fraction] = (e.target.value || '0').split('.');
    let formattedValue = `${parseInt(decimal)}`;

    if (fraction?.length > 2) return;
    else if (fraction?.length > 0) formattedValue = `${formattedValue}.${fraction}`;

    onChange(formattedValue);
  };

  return (
    <div>
      <FormToolTip label={label} tooltip={tooltip} />
      <div className='tw-flex tw-items-center'>
        <input
          type='range'
          min={min || 0}
          max={max || 100}
          step={0.1}
          value={current}
          onChange={(e) => onChange(e.target.value)}
          className='custom-range-slider tw-w-[359px] tw-h-2'
        />
        <div
          className='f-14-500 tw-h-14 tw-w-20 tw-px-2 tw-ml-3.5 tw-border tw-border-DIVIDER_GRAY tw-text-TEXT_GRAY_1 tw-bg-BASE_SECONDARY tw-rounded
        tw-flex tw-items-center tw-justify-center'
        >
          <input
            value={current}
            type='number'
            min={min || 0}
            max={max || 100}
            step={0.1}
            onChange={handleInputChange}
            className='tw-text-center tw-w-full'
            data-testid={`range-slider-input-${id}`}
          />
          <span className='tw-pr-2'>%</span>
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;
