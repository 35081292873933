import { useEffect, useState } from 'react';
import { EMAIL_REGEX } from 'constants/regex';
import { Button } from 'destiny/dist/components/molecules/button';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import Input from 'destiny/dist/components/organisms/input';
import { defaultFn } from 'destiny/dist/constants';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { BUTTON_SIZE_TYPES } from 'destiny/dist/constants/molecules/buttons';
import useGenerateShareableForm from 'hooks/useGenerateShareableForm';
import RegexParser from 'regex-parser';
import { CreateShareableFormFlowParams } from 'types/shareableForm.types';
import { copyToClipBoard, isDashboardShareableFormUrl } from 'utils/common';
import { trackMixpanel } from 'utils/mixpanel';
import CopiedTooltip from 'components/copied-tooltip/CopiedTooltip';

interface GenerateShareableFormParams {
  onLinkGenerated: (key: string, id: string, link: string) => void;
  apiParams: CreateShareableFormFlowParams;
  fieldKey: string;
  value: string;
  email: string;
  onEmailChange: (email: string) => void;
}

const GenerateShareableForm: React.FC<GenerateShareableFormParams> = ({
  onLinkGenerated,
  apiParams,
  fieldKey,
  value = '',
  email = '',
  onEmailChange = defaultFn,
}) => {
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);
  const [error, setError] = useState('');

  const {
    generateLink,
    isLoading,
    isError,
    error: apiError,
  } = useGenerateShareableForm({
    onLinkGenerated,
    apiParams,
    key: fieldKey,
  });

  useEffect(() => {
    setError(apiError?.data?.error?.message || '');
  }, [isError]);

  const handleGenerateLink = () => {
    if (!email) {
      setError('Please enter email');

      return;
    } else if (!new RegExp(RegexParser(EMAIL_REGEX)).test(email)) {
      setError('Entered email is invalid');

      return;
    }
    setError('');
    generateLink(email);
  };

  const handleCopy = () => {
    copyToClipBoard(value);
    setShowCopyTooltip(true);
    setTimeout(() => {
      setShowCopyTooltip(false);
    }, 2000);
  };

  const isLinkGenerated = !!value && isDashboardShareableFormUrl(value);

  return (
    <div className='tw-flex tw-items-start tw-gap-2 tw-mt-2'>
      <Input
        showLabel
        labelProps={{
          description: 'Receive a secure link via email to share your details',
          descriptionClass: 'f-14-300',
        }}
        inputFieldProps={{
          size: 'MEDIUM',
          inputTagProps: {
            placeHolder: 'Enter email id',
            value: email || '',
            onChange: (evt) => onEmailChange(evt.target.value),
            disabled: isLinkGenerated,
          },
        }}
        supporterInfoProps={{
          text:
            error ||
            (isLinkGenerated ? (
              <div className='tw-flex tw-gap-1 tw-relative'>
                Didn’t receive the mail?
                <span className='tw-text-ZAMP_PRIMARY tw-flex tw-gap-1 tw-cursor-pointer' onClick={handleCopy}>
                  <SvgSpriteLoader id='copy-03' iconCategory={ICON_SPRITE_TYPES.GENERAL} />
                  Copy Link
                </span>
                <CopiedTooltip show={showCopyTooltip} className='tw-absolute tw-right-0 !tw-top-[-30px]' />
              </div>
            ) : (
              ''
            )),
          textClass: error ? 'f-14-300 tw-text-ERROR_RED' : 'f-14-300 tw-text-TEXT_SECONDARY',
        }}
        showSupporterInfo={!!error || !!value}
      />
      <Button
        buttonProps={{
          size: BUTTON_SIZE_TYPES.MEDIUM,
          wrapperClass: 'tw-mt-12 tw-mr-0.5 tw-w-36 !tw-h-[52px] tw-overflow-clip',
          id: 'FORM_GENERATE_SHAREABLE_LINK',
          eventCallback: trackMixpanel,
          onClick: handleGenerateLink,
          isLoading: isLoading,
          disabled: isLinkGenerated,
        }}
      >
        {isLinkGenerated ? (
          <span className='tw-flex tw-items-center tw-gap-2'>
            <SvgSpriteLoader id='check-circle' iconCategory={ICON_SPRITE_TYPES.GENERAL} />
            Sent
          </span>
        ) : (
          'Send Link'
        )}
      </Button>
    </div>
  );
};

export default GenerateShareableForm;
