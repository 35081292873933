export const KYBstatus = {
  status: 'success',
  date: new Date(),
  application_details: '100',
  documents_upload: '100',
  className: '',
};

export const DISABLE_NEXT_KEY = 'disableNext';

export const CONTROL_FILEDS = [DISABLE_NEXT_KEY];

export const SHAREABLE_FORM_LINK_SUFFIX = '_shareable_form_link';
export const SHAREABLE_FORM_ID_SUFFIX = '_shareable_form_id';
export const SHAREABLE_FORM_EMAIL_SUFFIX = '_shareable_form_email';
