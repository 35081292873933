import { type FC } from 'react';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { Tooltip } from 'destiny/dist/components/molecules/tooltip/index';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { TooltipPositions } from 'destiny/dist/constants/index';

type LabelWithTooltipProps = {
  label: string;
  tooltip: string;
};

const LabelWithTooltip: FC<LabelWithTooltipProps> = ({ label, tooltip }) => (
  <div className='tw-flex tw-items-center tw-gap-2'>
    <div>{label}</div>
    <Tooltip
      tooltipBody={tooltip}
      color={COLORS.TEXT_PRIMARY}
      position={TooltipPositions.TOP}
      tooltipBodystyle='f-12-300 !tw-py-2 !tw-px-3 !tw-rounded tw-w-96 tw-min-w-[384px]'
    >
      <SvgSpriteLoader id='info-circle' iconCategory={ICON_SPRITE_TYPES.GENERAL} width={16} height={16} />
    </Tooltip>
  </div>
);

export default LabelWithTooltip;
