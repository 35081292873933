import { FC } from 'react';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { MenuItem } from 'types';

interface TabItemProps {
  data?: MenuItem;
  selected: boolean;
}

const TabItem: FC<TabItemProps> = ({ data, selected = false }) => {
  return (
    <div
      className={`tw-w-full tw-flex tw-gap-2 tw-items-center tw-justify-center tw-border tw-rounded tw-py-4 tw-px-2 ${
        selected ? 'tw-border-TEXT_PRIMARY' : 'tw-border-DIVIDER_GRAY'
      }`}
    >
      <SvgSpriteLoader
        id={data?.iconId ?? 'bank'}
        iconCategory={(data?.iconCategory as ICON_SPRITE_TYPES) ?? ICON_SPRITE_TYPES.FINANCE_AND_ECOMMERCE}
        width={16}
        height={16}
        color={COLORS.ORANGE_PRIMARY}
      />
      <div className='f-14-400 tw-whitespace-nowrap'>{data?.label ?? ''}</div>
    </div>
  );
};

export default TabItem;
