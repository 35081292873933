import { DragEventHandler, useState } from 'react';
import { PDF } from 'constants/icons';
import SvgSpriteLoader from 'destiny/dist/components/molecules/SvgSpriteLoader';
import { COLORS } from 'destiny/dist/constants/colors';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { defaultFnType } from 'destiny/dist/types';
import { useIsSuperUser } from 'hooks';
import Image from 'next/image';
import { isDashboardShareableFormUrl } from 'utils/common';
import CopiedTooltip from 'components/copied-tooltip/CopiedTooltip';

interface UploadProps {
  title?: string;
  description?: string;
  footer?: string;
  errorMsg?: string;
  filesSelected: string;
  onFilesSelect: (arg: string | null) => any;
  className?: string;
  children: React.ReactNode;
  handleClick: defaultFnType;
  error: string | null;
  isUploading: boolean;
  onFileDrop: (file: File | null) => void;
  linkGeneration?: boolean;
  onGenerateLink?: defaultFnType;
  showCopyTooltip?: boolean;
  id?: string;
}

const FileUploader: React.FC<UploadProps> = ({
  title,
  description,
  footer,
  errorMsg,
  filesSelected,
  onFilesSelect,
  className,
  children,
  handleClick,
  error,
  isUploading,
  onFileDrop,
  linkGeneration,
  onGenerateLink,
  showCopyTooltip = false,
  id,
}) => {
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const isSuperUser = useIsSuperUser();

  const handleFileDrop: DragEventHandler<HTMLDivElement> = (event) => {
    event?.preventDefault();
    event?.stopPropagation();

    const files = event?.dataTransfer?.files?.[0];

    setIsDragOver(false);
    onFileDrop(files);
  };

  const getUploadedText = () => {
    return typeof filesSelected === 'string' && isDashboardShareableFormUrl(filesSelected)
      ? 'Link Generated'
      : 'File Uploaded';
  };

  const isFileUploaded =
    filesSelected && typeof filesSelected === 'string' && !isDashboardShareableFormUrl(filesSelected);

  return (
    <div className='tw-flex tw-flex-col' data-testid={`file-uploader-${id}`}>
      {!!title && <div className='f-16-400 tw-text-TEXT_GRAY_2'>{title}</div>}
      {!!description && <div className='f-14-300 tw-pt-3 tw-text-TEXT_GRAY_4'>{description}</div>}
      <div
        className={`tw-min-h-[130px] tw-flex tw-flex-col tw-justify-center tw-items-center
    tw-border tw-border-dashed  tw-rounded-lg tw-mt-3 tw-p-4 ${className}
    ${errorMsg ? 'tw-border-ERROR_RED' : 'tw-border-ZAMP_PRIMARY'} ${
          isDragOver ? 'tw-bg-LAVENDER_4' : 'tw-bg-[#F9FAFF]'
        }`}
        onDrop={handleFileDrop}
        onDragOver={(event) => event.preventDefault()}
        onDragEnter={() => setIsDragOver(true)}
        onDragExit={() => setIsDragOver(false)}
        onDragLeave={() => setIsDragOver(false)}
      >
        {filesSelected ? (
          <div className='tw-flex tw-flex-wrap tw-justify-center tw-gap-2.5'>
            <div
              className='tw-h-8 tw-w-1/2 tw-p-3 tw-relative tw-flex tw-items-center tw-bg-LAVENDER_4 tw-rounded-full'
              key={filesSelected}
            >
              <Image src={PDF} alt='pdf-icon' className='tw-pr-1.5' draggable='false' width={21} height={14} />
              <div className='f-16-400 tw-h-5 tw-truncate tw-w-3/4'>{filesSelected}</div>
              <SvgSpriteLoader
                id='x-close'
                iconCategory={ICON_SPRITE_TYPES.GENERAL}
                width={16}
                height={16}
                onClick={() => onFilesSelect(null)}
                className='tw-absolute tw-right-3 tw-cursor-pointer'
              />
            </div>
          </div>
        ) : (
          <div className='f-14-300' onDragOver={() => setIsDragOver(true)}>
            Drag and drop your document or
          </div>
        )}
        <div className='tw-flex tw-items-center tw-justify-center tw-gap-3 tw-mt-2.5'>
          <div
            role='presentation'
            onClick={handleClick}
            onDragOver={() => setIsDragOver(true)}
            className='f-14-500 button-gradient tw-w-32 tw-h-9 tw-flex tw-items-center tw-justify-center tw-gap-2 tw-text-TEXT_GRAY_1
      tw-border tw-border-GRAY_1 tw-rounded-full tw-cursor-pointer'
          >
            <SvgSpriteLoader
              id='upload-01'
              iconCategory={ICON_SPRITE_TYPES.GENERAL}
              width={16}
              height={16}
              color={COLORS.ZAMP_PRIMARY}
            />
            Upload
          </div>
          {linkGeneration && !isFileUploaded && isSuperUser && (
            <>
              OR
              <div className='tw-relative'>
                <CopiedTooltip
                  show={showCopyTooltip}
                  wrapperOverrideClassName='tw-right-0 !tw-rounded-[5px] !tw-py-1 !tw-px-6 !tw-h-6 !-tw-top-8'
                />
                <div
                  role='presentation'
                  onClick={onGenerateLink}
                  onDragOver={() => setIsDragOver(true)}
                  className='f-14-500 button-gradient tw-w-32 tw-h-9 tw-flex tw-items-center tw-justify-center tw-gap-2 tw-text-TEXT_GRAY_1
      tw-border tw-border-GRAY_1 tw-rounded-full tw-cursor-pointer'
                >
                  <SvgSpriteLoader
                    id={filesSelected ? 'copy-02' : 'link-03'}
                    iconCategory={ICON_SPRITE_TYPES.GENERAL}
                    width={16}
                    height={16}
                    color={COLORS.ZAMP_PRIMARY}
                  />
                  {filesSelected ? 'Copy Link' : 'Link'}
                </div>
              </div>
            </>
          )}
        </div>

        {error && <div className='tw-text-xs tw-pt-1 tw-text-red-500'>{error}</div>}
        {filesSelected && (
          <div className='tw-text-xs tw-pt-1 tw-text-green-400' data-testid={`file-uploaded-text-${id}`}>
            {getUploadedText()}
          </div>
        )}
        {isUploading && <div className='tw-text-xs tw-pt-1 tw-text-blue-400'>Uploading...</div>}

        {children}
      </div>
      <div className='f-12-300 tw-text-TEXT_GRAY_1 tw-leading-6 tw-pt-1.5'>{footer}</div>
      {!!errorMsg && <div className={`tw-text-ERROR_RED tw-text-xs tw-mt-2`}>{errorMsg}</div>}
    </div>
  );
};

export default FileUploader;
