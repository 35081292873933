import { CONTROL_FILEDS, SHAREABLE_FORM_LINK_SUFFIX } from 'constants/formData';
import { ATTENTION, SUCCESS } from 'constants/icons';
import { FormFieldType, StatusType } from 'constants/kyb';
import { MapAny } from 'destiny/dist/types';
import RegexParser from 'regex-parser';
import { MapString, MenuItem, PRODUCT_TYPES } from 'types';
import { FieldType, Map } from 'types/kyb';
import { checkActiveIfValid, checkIsObjectEmpty, getTncServiceCode, isDashboardShareableFormUrl } from 'utils/common';

export const validateForms = (formStructure: any, data: any, allValues?: any[]) => {
  let errors: Map = {};

  if (formStructure?.is_repeatable)
    data[formStructure.key].forEach((item: any, index: number) => {
      errors = { ...errors, [`${formStructure.key}_${index}`]: validate(formStructure.form_fields, item, allValues) };
    });
  else errors = validate(formStructure.form_fields, data, allValues);

  return errors;
};

const validate = (structure: any, data: any, allValues?: any[]) => {
  let errors: Record<string, unknown> = {};

  structure.forEach((structure: FieldType) => {
    const { validation_rules, type, key, is_required, allow_link_generation } = structure;
    const fieldValue = getFieldValue(data, type, key);
    const activeIfValid = checkActiveIfValid(structure, data, allValues);
    const fieldShareableLinkValue = allow_link_generation
      ? getFieldValue(data, type, key + SHAREABLE_FORM_LINK_SUFFIX)
      : '';

    if (
      activeIfValid &&
      is_required &&
      (!fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0)) &&
      !(allow_link_generation && fieldShareableLinkValue && isDashboardShareableFormUrl(fieldShareableLinkValue))
    )
      errors = { ...errors, [key]: true };
    else if (
      activeIfValid &&
      validation_rules &&
      fieldValue &&
      !new RegExp(RegexParser(validation_rules)).test(fieldValue)
    )
      errors = { ...errors, [key]: true };
    else if (type === FormFieldType.PHONE_NUMBER) {
      const phoneValue = JSON.parse(fieldValue);

      if (!phoneValue.phone || !phoneValue.dial_code) errors = { ...errors, [key]: true };
    }
  });

  return errors;
};

export const getFieldValue = (data: any, type: any, key: string) => {
  let value: string = data[key];

  if (type === FormFieldType.SELECT) value = data[key]?.value;

  return value;
};

export const constructFieldData = (
  fields: any,
  prefilledData: Record<string, string> = {},
  noDefaultValueFields?: string[]
) => {
  const fieldData: any = {};

  fields.forEach((item: any) => {
    if (item.type === FormFieldType.SLIDER) fieldData[item.key] = prefilledData[item.key] ?? '50';
    else if (
      (item.type === FormFieldType.SELECT ||
        item.type === FormFieldType.REGION_PICKER ||
        item.type === FormFieldType.MULTI_SELECT) &&
      prefilledData[item.key]
    )
      fieldData[item.key] = JSON.parse(prefilledData[item.key]);
    else if (
      (item.type === FormFieldType.SELECT || item.type === FormFieldType.TABS) &&
      !noDefaultValueFields?.includes(item.key) &&
      checkActiveIfValid(item, fieldData)
    ) {
      const defaultOption = item?.options?.find((option: any) => option?.id === item?.default);

      if (!checkIsObjectEmpty(defaultOption)) {
        fieldData[item.key] = {
          value: defaultOption?.id,
          label: defaultOption?.value,
          isDisabled: false,
          iconId: defaultOption?.icon_id,
          iconCategory: defaultOption?.icon_category,
          icon: null,
          info: defaultOption?.info,
        };
      }
    } else if (checkActiveIfValid(item, fieldData) && !noDefaultValueFields?.includes(item.key)) {
      fieldData[item.key] = prefilledData[item.key] || item?.default || null;
    }
  });

  return fieldData;
};

export const constructFormStateData = (
  data: Array<any>,
  prefilledData: Record<string, string> = {},
  noDefaultValueFields?: string[]
) =>
  data.map((data: any) => {
    let fields: any = {};

    if (data?.is_repeatable)
      fields[data.key] = prefilledData[data.key]
        ? JSON.parse(prefilledData[data.key])
        : [constructFieldData(data.form_fields, {}, noDefaultValueFields)];
    else fields = { ...fields, ...constructFieldData(data.form_fields, prefilledData, noDefaultValueFields) };

    return fields;
  });

export const getDetailsFromStatus = (status: string) => {
  switch (status) {
    case StatusType.SUCCESS:
      return {
        icon: SUCCESS,
        bgColour: '#C6FFC1',
        colour: '#468713',
        status: 'Success',
        title: 'Congratulations! Your account is verified',
      };
    case StatusType.INITIATED:
      return {
        icon: SUCCESS,
        bgColour: '#D8E5FF',
        colour: '#2546F5',
        status: 'In Progress',
        title: 'Thank you for submitting KYB details',
      };
    case StatusType.FAILED:
    case StatusType.INFO_REQUESTED:
    case StatusType.EXPIRED:
      return { icon: ATTENTION, bgColour: '#FFD9D9', colour: '#871313', status: 'Failed', title: 'Attention required' };
  }
};

export const getFormattedDate = (date: Date) => {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return `${monthNames[`${date.getMonth()}`]} ${date.getDate()}, ${date.getFullYear()}`;
};

const duplicateData = (copyMap: MapAny, dataMap: MapAny): MapString => {
  if (!copyMap) return {};

  const data: MapString = {};

  Object.keys(copyMap).forEach((key) => {
    const value = copyMap?.[key];

    if (value) data[key] = dataMap?.[value];
  });

  return data;
};

export const formatData = (
  formData: any,
  formStructure: any,
  isTncAccepted: boolean,
  tncViewdTimestamp: number,
  entityIntents: PRODUCT_TYPES[]
) => {
  let data: Array<any> = [];
  let numberOfOwners = 0;

  formData.forEach((item: any, index: number) => {
    if (formStructure[index].key === 'company_owners') {
      numberOfOwners = item?.company_owners?.length;
    }
    if (
      formStructure[index].key === 'company_address' &&
      item?.['current_address.same_as_registered_address'] === 'true'
    ) {
      const copyMap = formStructure[index]?.form_fields?.find(
        (field: any) => field.key === 'current_address.same_as_registered_address'
      )?.copy_map;

      item = { ...item, ...duplicateData(copyMap, item) };
    }

    data = [...data, ...formatEachFormData(item, formStructure[index].key)];
  });

  const region = data.find((item: any) => item.key === 'registered_address.country')?.value || 'US';

  return {
    region,
    kyb_details: data,
    is_tnc_accepted: isTncAccepted,
    tnc_accepted_at: new Date().getTime(),
    tnc_viewed_at: tncViewdTimestamp,
    tnc_service_code: getTncServiceCode(entityIntents),
    number_of_owner: numberOfOwners,
  };
};

export const formatEachFormData = (formData: any, parent?: string) => {
  let data: Array<any> = [];

  Object.keys(formData).forEach((key: string) => {
    if (CONTROL_FILEDS.includes(key)) return;
    else if (Array.isArray(formData[key]) && key !== 'country_of_operations')
      data = [...data, ...formatRepeatableFormData(key, formData)];
    else if (formData[key] && typeof formData[key] === 'object')
      if (key === 'country_of_operations')
        data = [...data, { key, value: getValueFromDropdownArray(formData[key]), parent }];
      else data = [...data, { key, value: formData[key].value, parent }];
    else data = [...data, { key, value: formData[key], parent }];
  });

  return data;
};

export const getLocaleStringOrEmpty = (value: string) => {
  return value.length > 0 ? parseInt(value).toLocaleString() : value;
};

export const formatRepeatableFormData = (key: string, formData: any) => {
  let data: Array<any> = [];

  formData[key].forEach((item: any, index: number) => {
    data = [...data, ...formatEachFormData(item, `${key}[${index}]`)];
  });

  return data;
};

export const getLabelFromDropdownArray = (value: MenuItem[]) => {
  const labels: string[] = [];

  value.forEach((item) => labels.push(item.label));

  return JSON.stringify(labels);
};

export const getValueFromDropdownArray = (value: MenuItem[]) => {
  const values = value?.reduce((acc: string[], { value }: MenuItem) => {
    acc.push(value as string);

    return acc;
  }, []);

  return JSON.stringify(values);
};
