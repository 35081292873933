/* eslint-disable @next/next/no-img-element */
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { DROPDOWN_ICON } from 'constants/icons';
import { useOnClickOutside } from 'hooks';
import { MenuItem } from 'types';
import { DropdownSizeType } from 'components/button/types';
import FormToolTip from 'components/tooltip/FormToolTip';

interface DropdownProps {
  id: string;
  label?: string;
  tooltip?: string;
  selectLabel: string;
  options: Array<MenuItem>;
  selectedItem?: MenuItem | undefined | null;
  onMenuItemClick: (menu: MenuItem, index: number) => void;
  type?: string;
  className?: string;
  hasError?: string;
  isSearchable?: boolean;
  clearSearchInput?: boolean;
  onSearch?: any;
  size?: DropdownSizeType;
  isDisabled?: boolean;
  customDropdown?:
    | {
        dropdownSelect?: string;
        wrapperClass?: string;
        sizeClass?: string;
        inputClass?: string;
        inputErrorClass?: string;
        menuWrapperClass?: string;
        menuItemClasses?: {
          menuSizeClass?: string;
          selectedItemIcon?: ReactNode;
          selectedItemTextClass?: string;
          defaultTextClass?: string;
          disabledMenuClass?: string;
          defaultMenuClass?: string;
          menuBorderClass?: string;
          activeItemClass?: string;
        };
        dropdownIndicatorClass?: string;
      }
    | undefined;
}

const noResultPlaceholder: MenuItem = {
  label: 'No matching results',
  value: '',
  isDisabled: true,
};

const sizeStyles = {
  [DropdownSizeType.NORMAL]: {
    inputBox: 'tw-h-[59px] 2xl_custom:tw-h-12',
    icon: 'tw-top-7 2xl_custom:tw-top-5',
    menu: 'tw-top-16 2xl_custom:tw-top-[50px]',
  },
  [DropdownSizeType.MEDIUM]: { inputBox: 'tw-h-10', icon: 'tw-top-5 ', menu: 'tw-top-10 2xl_custom:tw-top-[40px]' },
  [DropdownSizeType.SMALL]: {
    inputBox: 'tw-h-9 tw-text-xs',
    icon: 'tw-top-4 2xl_custom:tw-top-3',
    menu: 'tw-top-10 2xl_custom:tw-top-[40px]',
  },
};

const Dropdown: React.FC<DropdownProps> = ({
  className = '',
  id,
  options,
  label,
  tooltip,
  selectLabel,
  hasError,
  selectedItem,
  onMenuItemClick,
  isSearchable = true,
  clearSearchInput = true,
  size = DropdownSizeType.NORMAL,
  isDisabled = false,
  customDropdown: {
    dropdownSelect = null,
    wrapperClass = null,
    sizeClass = null,
    inputClass = null,
    inputErrorClass = null,
    menuWrapperClass = null,
    dropdownIndicatorClass = null,
    menuItemClasses: {
      menuSizeClass = null,
      selectedItemIcon = null,
      selectedItemTextClass = null,
      defaultTextClass = null,
      disabledMenuClass = null,
      defaultMenuClass = null,
      menuBorderClass = null,
      activeItemClass = null,
    } = {},
  } = {},
}) => {
  const [isOpen, toggleOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(selectedItem?.label || '');
  const [activeItemIndex, setActiveIndexItem] = useState(-1);
  const [filteredOptions, setFilteredOptions] = useState(options);

  const activeItemRef = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useOnClickOutside(ref, () => {
    toggleOpen(false);
  });

  useEffect(() => {
    if (isOpen) {
      setActiveIndexItem(0);
      ref?.current?.scrollIntoView({ block: 'center', inline: 'start', behavior: 'smooth' });
    }
  }, [isOpen]);

  useEffect(() => {
    setSearchTerm(selectedItem?.label || '');
  }, [selectedItem]);

  useEffect(() => {
    const filtered = options.filter((item) => item.label?.toLowerCase().includes(searchTerm.toLowerCase()));

    if (filtered.length) setActiveIndexItem(0);
    else setActiveIndexItem(-1);

    setFilteredOptions(filtered);
  }, [searchTerm, isOpen]);

  const handleDropDownKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (filteredOptions[activeItemIndex]?.isDisabled || activeItemIndex === -1) return;
      onMenuItemClick(filteredOptions[activeItemIndex], activeItemIndex);
      setSearchTerm(filteredOptions[activeItemIndex]?.label);
      toggleOpen(false);
    } else if (e.key === 'Escape') {
      setSearchTerm(selectedItem?.label || '');
      toggleOpen(false);
    } else if (e.key === 'ArrowDown') {
      setActiveIndexItem(Math.min(activeItemIndex + 1, filteredOptions.length - 1));
      activeItemRef.current?.scrollIntoView({ block: 'center', inline: 'start', behavior: 'smooth' });
    } else if (e.key === 'ArrowUp') {
      setActiveIndexItem(Math.max(activeItemIndex - 1, 0));
      activeItemRef.current?.scrollIntoView({ block: 'center', inline: 'start', behavior: 'smooth' });
    }
  };

  const getDropdownOptions = () => {
    return (filteredOptions?.length ? filteredOptions : [noResultPlaceholder]).map(renderMenuItem);
  };

  const renderMenuItem = (menu: MenuItem, index: number, list: Array<any>) => {
    return (
      <div
        className={`tw-w-full ${sizeStyles[size].inputBox} tw-flex tw-items-center ${menuSizeClass ?? 'tw-px-6'} ${
          menu.isDisabled
            ? `${disabledMenuClass ?? 'tw-text-GRAY_3 tw-cursor-default'}`
            : `${defaultMenuClass ?? 'tw-text-TEXT_PRIMARY hover:tw-bg-BACKGROUND_LIGHT_BLUE'}`
        } ${index + 1 === list.length ? '' : `${menuBorderClass ?? 'tw-border-b tw-border-b-DIVIDER_GRAY'}`} ${
          selectedItem?.value === menu?.value
            ? `${selectedItemTextClass ?? 'tw-font-medium'}`
            : `${defaultTextClass ?? 'tw-font-light'}`
        }
      ${index === activeItemIndex ? `${activeItemClass ?? 'tw-bg-BACKGROUND_LIGHT_BLUE'}` : ''}`}
        role='button'
        key={index}
        tabIndex={-1}
        ref={index === activeItemIndex ? activeItemRef : null}
        onClick={() => {
          if (menu.isDisabled) return;
          toggleOpen(false);
          onMenuItemClick(menu, index);
          setSearchTerm(menu?.label);
        }}
        data-testid={`dropdown-item-${id}-${index}`}
      >
        <span>{menu.label}</span>

        {selectedItem?.value === menu?.value && selectedItemIcon ? selectedItemIcon : null}
      </div>
    );
  };

  return (
    <div
      role='presentation'
      onBlur={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setSearchTerm(selectedItem?.label || '');
          toggleOpen(false);
        }
      }}
    >
      {(label || tooltip) && <FormToolTip label={label} tooltip={tooltip} />}

      <div className={`tw-relative ${wrapperClass ?? 'f-14-300 tw-text-TEXT_GRAY_2'} ${className} `} ref={ref}>
        <div
          id={id}
          className={`tw-w-full ${sizeClass ?? sizeStyles[size].inputBox} tw-relative tw-cursor-pointer
        `}
          role='presentation'
          tabIndex={-1}
        >
          <div className='tw-absolute tw-w-full tw-h-full tw-flex tw-items-center tw-bg-white'>
            {isSearchable && (
              <input
                ref={inputRef}
                id={`${id}-search`}
                type='text'
                autoFocus={isOpen}
                placeholder={selectLabel}
                value={searchTerm}
                disabled={!options?.length || isDisabled}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  toggleOpen(true);
                  setSearchTerm(event?.target?.value || '');
                }}
                className={`${
                  inputClass ??
                  'tw-w-full tw-h-full tw-rounded tw-bg-white tw-border focus:tw-outline-none  focus:tw-border-ZAMP_PRIMARY disabled:tw-bg-BASE_PRIMARY disabled:tw-cursor-not-allowed'
                } ${hasError ? `${inputErrorClass ?? 'tw-border-ERROR_RED'}` : 'tw-border-GRAY_1'} ${
                  isOpen && 'tw-font-normal'
                } ${dropdownSelect ?? 'tw-px-[17px]'}`}
                onFocus={() => {
                  toggleOpen(true);
                  if (clearSearchInput) setSearchTerm('');
                }}
                onKeyUp={handleDropDownKeyUp}
                autoComplete='off'
              />
            )}
            {!isSearchable && (
              <div
                role='button'
                onClick={() => {
                  toggleOpen(true);
                  setSearchTerm('');
                }}
                onFocus={() => {
                  toggleOpen(true);
                  setSearchTerm('');
                }}
                onKeyUp={handleDropDownKeyUp}
                className={`${
                  inputClass ??
                  'tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center  tw-rounded tw-bg-white tw-border focus:tw-outline-none  focus:tw-border-ZAMP_PRIMARY'
                } ${hasError ? `${inputErrorClass ?? 'tw-border-ERROR_RED'}` : 'tw-border-GRAY_1'} ${
                  dropdownSelect ?? 'tw-px-[17px]'
                }`}
                tabIndex={0}
              >
                {searchTerm || 'Select'}
              </div>
            )}
          </div>

          <img
            src={DROPDOWN_ICON}
            alt=''
            draggable='false'
            width='10px'
            onClick={() => inputRef?.current?.focus()}
            className={`${dropdownIndicatorClass ?? `tw-absolute tw-right-4 ${sizeStyles[size].inputBox}`}`}
          />
        </div>
        {isOpen && !!options.length && (
          <div
            className={`tw-absolute tw-z-10 tw-w-full tw-pb-6 tw-top-16 2xl_custom:tw-top-[50px] ${sizeStyles[size].menu}`}
          >
            <div
              className={`${
                menuWrapperClass ?? 'tw-max-h-[250px] tw-rounded tw-shadow-xl '
              } tw-w-full dropdown-select tw-bg-white tw-cursor-pointer tw-overflow-y-auto tw-border tw-border-DIVIDER_GRAY`}
            >
              {getDropdownOptions()}
            </div>
          </div>
        )}
      </div>
      {!!hasError && (
        <div className='f-14-300 tw-h-min-4 tw-h-fit tw-text-ERROR_RED tw-mt-2 2xl_custom:tw-mt-1'>{hasError}</div>
      )}
    </div>
  );
};

export default Dropdown;
