import { PLATFORM_IDEMPOTENCY_HEADER_KEY } from 'constants/config';
import { APITags, REQUEST_TYPES } from 'constants/index';
import baseApi from 'services/api';
import {
  CreateShareableFormRequest,
  CreateShareableFormResponse,
  GenerateSignedUrlPreloginRequest,
  GetShareableFormResponse,
  UpdateShareableFormRequest,
} from 'types/shareableForm.types';

const URL_PREFIX = 'v1/shareable-form';

const ShareableForm = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getShareableFormDetails: builder.query<GetShareableFormResponse, string>({
      query: (uid) => ({
        url: `${URL_PREFIX}/${uid}`,
      }),
      transformResponse: ({ data }) => data,
      providesTags: [APITags.GET_SHAREABLE_FORM],
    }),
    createShareableForm: builder.mutation<CreateShareableFormResponse, CreateShareableFormRequest>({
      query: ({ idempotencyHeader, data }) => {
        return {
          url: URL_PREFIX,
          method: REQUEST_TYPES.POST,
          body: data,
          headers: {
            [PLATFORM_IDEMPOTENCY_HEADER_KEY]: idempotencyHeader,
          },
        };
      },
      transformResponse: ({ data }) => data,
    }),
    updateShareableForm: builder.mutation<GetShareableFormResponse, UpdateShareableFormRequest>({
      query: ({ uid, data }) => {
        const url = `${URL_PREFIX}/${uid}`;

        return {
          url: url,
          method: REQUEST_TYPES.PATCH,
          body: data,
        };
      },
      transformResponse: ({ data }) => data,
      invalidatesTags: (data, error) => {
        if (!error) return [APITags.GET_SHAREABLE_FORM];

        return [];
      },
    }),
    generateSignedUploadUrl: builder.mutation<GetShareableFormResponse, GenerateSignedUrlPreloginRequest>({
      query: (data) => {
        const url = `${URL_PREFIX}/upload-url`;

        return {
          url: url,
          method: REQUEST_TYPES.POST,
          body: data,
        };
      },
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const {
  useGetShareableFormDetailsQuery,
  useCreateShareableFormMutation,
  useUpdateShareableFormMutation,
  useGenerateSignedUploadUrlMutation,
} = ShareableForm;
