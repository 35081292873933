import baseApi from 'services/api';
import { CountryResponseTypes } from 'types/formApi.types';

const Form = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCountry: builder.query<CountryResponseTypes[], void>({
      query: () => ({
        url: `v1/form/countries`,
      }),
      transformResponse: ({ data }) => data,
    }),
    getRegionByCountry: builder.query<any, any>({
      query: (country) => ({
        url: `v1/form/regions/${country}`,
      }),
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const { useGetAllCountryQuery, useLazyGetRegionByCountryQuery } = Form;
