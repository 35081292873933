import { useEffect, useRef, useState } from 'react';
import { useGetSignedUrlMutation } from 'api/kyb';
import { useGenerateSignedUploadUrlMutation } from 'api/shareableForm';
import { FileExtensionToInputFormatMapping, FileExtensionToTypeMap, FileMimeType } from 'constants/fileMimeTypes';
import { FILE_SIZE, REQUEST_TYPES } from 'constants/index';
import { defaultFnType } from 'destiny/dist/types';
import { useAppSelector } from 'hooks/toolkit';
import { RootState } from 'store';
import { copyToClipBoard } from 'utils/common';

interface FileUploaderWrapperProps {
  disableNext: (arg: boolean) => any;
  acceptedFormats: string;
  title?: string;
  footer?: string;
  filesSelected: string;
  errorMsg?: string;
  onFilesSelect: (arg: string | null) => any;
  isFileUploading?: boolean;
  maxSize?: number;
  setParentError?: (arg: string) => void;
  Component: React.ElementType;
  isSuccess?: boolean;
  isPrelogin?: boolean;
  preLoginToken?: string;
  linkGeneration?: boolean;
  onGenerateLink?: defaultFnType;
  id?: string;
  description?: string | null;
  className?: string;
}

const FileUploaderWrapper: React.FC<FileUploaderWrapperProps> = ({
  disableNext,
  acceptedFormats,
  onFilesSelect,
  isFileUploading = false,
  maxSize = FILE_SIZE.TWO_MB,
  filesSelected,
  setParentError,
  Component,
  isPrelogin,
  preLoginToken = '',
  onGenerateLink,
  id,
  ...rest
}) => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCopyTooltip, setShowCopyTooltip] = useState<boolean>(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const {
    user: { merchant_id },
  } = useAppSelector((state: RootState) => state.user);

  const [getSignedUrl] = useGetSignedUrlMutation({});
  const [getSignedUrlPrelogin] = useGenerateSignedUploadUrlMutation({});

  useEffect(() => {
    if (filesSelected && isLoading) {
      setIsLoading(false);
    }
  }, [filesSelected]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const filesToUpload: File | null = event.target.files && event.target.files[0];

    handleUpload(filesToUpload);
  };

  const handleUpload = (filesToUpload: File | null) => {
    if (isLoading) return;

    if (hiddenFileInput?.current) hiddenFileInput.current.value = '';
    if (filesToUpload)
      if (filesToUpload?.size > maxSize) {
        const err = `File size cannot exceed more than ${maxSize / FILE_SIZE.ONE_MB}MB`;

        setError(err);
        setParentError && setParentError(err);
      } else {
        const fileExtension: string = filesToUpload?.name?.split('.')?.pop() ?? '';

        //please add file type in FileMimeType on add new file format
        const fileName = merchant_id + '_' + Date.now() + '.' + (FileMimeType[filesToUpload.type] ?? fileExtension);

        const acceptedFormatsArr = acceptedFormats.split(',').map((item) => item.trim());
        const generateUploadUrlFn = isPrelogin ? getSignedUrlPrelogin : getSignedUrl;
        const payload = {
          file_extension: FileMimeType[filesToUpload.type] ?? fileExtension,
          id: preLoginToken,
          key: fileName,
        };

        const isAllowedFormat = acceptedFormatsArr.includes(FileExtensionToInputFormatMapping[fileExtension]);

        if (isAllowedFormat) {
          setError(null);
          setParentError && setParentError('');
          onFilesSelect(null);
          setIsLoading(true);
          disableNext(true);
          generateUploadUrlFn(payload)
            .unwrap()
            .then(async (uploadUrl: any) => {
              if (uploadUrl) {
                const headers = new Headers();

                headers.append('Content-Type', filesToUpload.type || FileExtensionToTypeMap[fileExtension]);

                const requestOptions: RequestInit = {
                  method: REQUEST_TYPES.PUT,
                  headers,
                  body: filesToUpload,
                };

                return fetch(uploadUrl.url, requestOptions)
                  .then((response) => {
                    if (response.status === 200) onFilesSelect(uploadUrl.identifier);
                  })
                  .catch((error) => console.log('error', error));
              }
            })
            .catch(() => {
              setError('Uploading failed!');
              setParentError && setParentError('Uploading failed!');
            })
            .finally(() => {
              setIsLoading(false);
              disableNext(false);
            });
        } else {
          const err = `Invalid file type`;

          setError(err);
          setParentError && setParentError(err);
        }
      }
  };

  const handleGenerateLink = () => {
    if (filesSelected) {
      setShowCopyTooltip(true);
      copyToClipBoard(filesSelected);
      setTimeout(() => {
        setShowCopyTooltip(false);
      }, 2000);
    } else {
      onGenerateLink?.();
    }
  };

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  return (
    <Component
      {...rest}
      onFilesSelect={onFilesSelect}
      handleClick={handleClick}
      isLoading={isLoading || isFileUploading}
      error={error}
      isUploading={isLoading}
      onFileDrop={handleUpload}
      onGenerateLink={handleGenerateLink}
      showCopyTooltip={showCopyTooltip}
      filesSelected={filesSelected}
      id={id}
    >
      <input
        type='file'
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept={acceptedFormats}
        data-testid={`file-uploader-input-${id}`}
      />
    </Component>
  );
};

export default FileUploaderWrapper;
