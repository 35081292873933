import { Children, cloneElement, isValidElement, ReactNode, useCallback, useEffect, useRef } from 'react';
import { Provider } from 'react-redux';
import { LAUNCH_DARKLY_FLAGS } from 'constants/launchDarkly';
import { KEYBOARD_SHORTCUT_KEYS } from 'constants/shortcuts';
import { defaultFnType } from 'destiny/dist/types';
import { useAppDispatch, useAppSelector } from 'hooks/toolkit';
import { RootState, store } from 'store';
import { addFullPageLayout, removeFullPageLayout } from 'store/slices/layout-configs';
import { clearShortcut } from 'store/slices/shortcuts';
import { CommonPageLayoutProps } from 'types';
import { checkShortcutMatch } from 'utils/common';
import { getVariation } from 'utils/launchDarkly';
import Topbar from 'components/layout/topbar/OverlayTopbar';
import OverlayTopBarV2 from 'components/layout/topbar/OverlayTopBarV2';

type FullPageLayoutProps = {
  pageType?: string;
  children: React.ReactNode;
  contentWrapperClassName?: string;
  layoutWidthOverrideClassName?: string;
  onClose?: defaultFnType;
  headerFixed?: boolean;
  topbarClassName?: string;
  bottomBar?: React.ReactNode;
  allowEscape?: boolean;
  id: string;
};

const FullPageLayout: React.FC<FullPageLayoutProps> = ({
  onClose,
  children,
  contentWrapperClassName = '',
  layoutWidthOverrideClassName = 'tw-w-[520px]',
  topbarClassName = '',
  headerFixed = true,
  bottomBar,
  allowEscape = true,
  id = 'GENERAL',
}) => {
  const { appliedShortcut } = useAppSelector((state: RootState) => state?.shortcuts);
  const { fullPageLayoutStack } = useAppSelector((state: RootState) => state?.layoutConfig);
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const isOtcEnabled = getVariation(LAUNCH_DARKLY_FLAGS.OTC);

  const LAYOUT_ID = id + '_FULL_PAGE_LAYOUT';

  useEffect(() => {
    dispatch(addFullPageLayout(LAYOUT_ID));

    return () => {
      dispatch(removeFullPageLayout(LAYOUT_ID));
    };
  }, []);

  useEffect(() => {
    if (
      allowEscape &&
      onClose &&
      checkShortcutMatch(appliedShortcut, KEYBOARD_SHORTCUT_KEYS.ESCAPE) &&
      fullPageLayoutStack?.at(-1) === LAYOUT_ID
    ) {
      dispatch(clearShortcut());
      onClose?.();
    }
  }, [appliedShortcut, onClose, allowEscape]);

  useEffect(() => {
    scrollToTop?.();
  }, [containerRef?.current]);

  const scrollToTop = useCallback(() => {
    setTimeout(() => {
      if (containerRef.current) containerRef.current.scrollTop = 0;
    }, 0);
  }, [containerRef?.current]);

  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      if (containerRef.current) containerRef.current.scrollTop = containerRef.current?.clientHeight;
    }, 0);
  }, [containerRef?.current]);

  const renderChildrenWithProps = (children: ReactNode) => {
    const childrenWithProps = Children.map(children, (child) => {
      if (isValidElement(child))
        return cloneElement(child as React.ReactElement<CommonPageLayoutProps>, {
          scrollToTop: scrollToTop,
          scrollToBottom: scrollToBottom,
          rootContainerRef: containerRef,
        });

      return child;
    });

    return childrenWithProps;
  };

  return (
    <Provider store={store}>
      <div className='tw-fixed tw-w-full tw-z-50 tw-left-0 tw-top-0 tw-bg-white'>
        {isOtcEnabled ? (
          <OverlayTopBarV2
            onClose={onClose}
            className={`tw-w-full tw-border-white ${topbarClassName} ${headerFixed ? 'tw-fixed tw-top-0' : ''}`}
          />
        ) : (
          <Topbar
            onClose={onClose}
            className={`tw-w-full tw-border-white ${topbarClassName} ${headerFixed ? 'tw-fixed tw-top-0' : ''}`}
          />
        )}

        <div
          ref={containerRef}
          className={`tw-w-full tw-pb-12 ${contentWrapperClassName} ${
            headerFixed
              ? isOtcEnabled
                ? 'tw-h-[calc(100vh-52px)] tw-mt-[52px] tw-overflow-y-scroll'
                : 'tw-h-[calc(100vh-56px)] tw-mt-14 tw-overflow-y-scroll'
              : 'tw-h-screen'
          }`}
        >
          <div className={`${layoutWidthOverrideClassName} tw-mx-auto`}>{renderChildrenWithProps(children)}</div>
        </div>
        {bottomBar}
      </div>
    </Provider>
  );
};

export default FullPageLayout;
