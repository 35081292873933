import { INPUT_FILE_FORMATS } from 'types/mime';

export const FileMimeType: Record<string, string> = {
  'application/pdf': 'pdf',
  'image/jpeg': 'jpeg',
  'image/png': 'png',
  'image/bmp': 'bmp',
  'text/csv': 'csv',
};

export const FileExtensionToTypeMap: Record<string, string> = {
  pdf: 'application/pdf',
  jpeg: 'image/jpeg',
  png: 'image/png',
  bmp: 'image/bmp',
  csv: 'text/csv',
};

export const FileExtensionToInputFormatMapping: Record<string, INPUT_FILE_FORMATS> = {
  pdf: INPUT_FILE_FORMATS.PDF,
  jpeg: INPUT_FILE_FORMATS.JPEG,
  png: INPUT_FILE_FORMATS.PNG,
  bmp: INPUT_FILE_FORMATS.BMP,
  csv: INPUT_FILE_FORMATS.CSV,
  jpg: INPUT_FILE_FORMATS.JPG,
  mp4: INPUT_FILE_FORMATS.MP4,
  mov: INPUT_FILE_FORMATS.MOV,
  avi: INPUT_FILE_FORMATS.AVI,
  wmv: INPUT_FILE_FORMATS.WMV,
  flv: INPUT_FILE_FORMATS.FLV,
  webm: INPUT_FILE_FORMATS.WEBM,
};
