import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useLazyGetRegionByCountryQuery } from 'api/form';
import { DATE_FORMATS } from 'constants/date';
import {
  DISABLE_NEXT_KEY,
  SHAREABLE_FORM_EMAIL_SUFFIX,
  SHAREABLE_FORM_ID_SUFFIX,
  SHAREABLE_FORM_LINK_SUFFIX,
} from 'constants/formData';
import { CHEVRON_DOWN, CROSS_GRAY, SELECTED_GREEN } from 'constants/icons';
import { COUNTRY_KEY, FormFieldType, STATE_KEY } from 'constants/kyb';
import { compareAsc, format } from 'date-fns';
import { Label } from 'destiny/dist/components/molecules/label';
import { MenuOption } from 'destiny/dist/components/molecules/menuOption';
import { SupporterInfo } from 'destiny/dist/components/molecules/supporterInfo';
import { Dropdown as DestinyDrop } from 'destiny/dist/components/organisms/dropdown';
import DestinyInput from 'destiny/dist/components/organisms/input';
import { ICON_SPRITE_TYPES } from 'destiny/dist/constants/icons';
import { defaultFn } from 'destiny/dist/constants/index';
import { SUPPORT_INFO_TYPES } from 'destiny/dist/constants/molecules/supporterInfo';
import Image from 'next/image';
import { MapAny, MenuItem } from 'types';
import { FieldType, FormType, InputOptionsType } from 'types/kyb';
import { INPUT_FILE_FORMATS } from 'types/mime';
import { CreateShareableFormFlowParams } from 'types/shareableForm.types';
import { checkActiveIfValid } from 'utils/common';
import { trackMixpanel } from 'utils/mixpanel';
import Dropdown from 'components/dropdown/Dropdown';
import FileUploader from 'components/file-uploader/FileUploader';
import FileUploaderWrapper from 'components/file-uploader/FileUploaderWrapper';
import GenerateShareableForm from 'components/form/GenerateShareableForm';
import LabelWithTooltip from 'components/form/LabelWithTooltip';
import BorderlessInput from 'components/input/BorderlessInput';
import Input from 'components/input/Input';
import Section from 'components/kyb/Section';
import UnorderedList from 'components/kyb/UnorderedList';
import PhoneNumber from 'components/PhoneNumber';
import Slider from 'components/range-slider/RangeSlider';
import TabsWithIcons from 'components/tabs-with-icons';
import Textarea from 'components/textarea/Textarea';
import FormToolTip from 'components/tooltip/FormToolTip';

interface FormProps {
  wrapperClass?: string;
  formFields: FormType | any;
  formState: any;
  errors: any;
  updateFormState: (key: string, state: any, type?: string) => void;
  defaultStyle?: boolean;
  customClassNames?:
    | {
        customDropdown?:
          | {
              dropdownSelect?: string;
              wrapperClass?: string;
              sizeClass?: string;
              inputClass?: string;
              inputErrorClass?: string;
              menuWrapperClass?: string;
              menuItemClasses?: {
                menuSizeClass?: string;
                selectedItemIcon?: ReactNode;
                selectedItemTextClass?: string;
                defaultTextClass?: string;
                disabledMenuClass?: string;
                defaultMenuClass?: string;
                menuBorderClass?: string;
                activeItemClass?: string;
              };
              dropdownIndicatorClass?: string;
            }
          | undefined;
        customInput?: string;
        customInputText?: {
          wrapperClass?: string;
          titleClass?: string;
          size?: any;
          descriptionClass?: string;
          errClassName?: string;
        };
        customDestinyDropdown?: MapAny;
        customCheckbox?: { labelOverrideClassName?: string; containerClass?: string };
      }
    | undefined;
  InfoComponent?: any;
  useDescriptionLabel?: boolean;
  isDestinyComponent?: boolean;
  scrollToErr?: boolean;
  setScrollToErr?: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  isPrelogin?: boolean;
  preLoginToken?: string;
  shareableFormParams?: CreateShareableFormFlowParams;
  allValues?: any[];
}

const Form: React.FC<FormProps> = ({
  wrapperClass = 'tw-flex tw-flex-col tw-gap-4',
  formFields,
  formState,
  errors,
  updateFormState,
  defaultStyle = true,
  customClassNames: {
    customDropdown = {},
    customInput,
    customInputText = {},
    customDestinyDropdown = {},
    customCheckbox = {},
  } = {},
  InfoComponent = null,
  useDescriptionLabel = false,
  isDestinyComponent = false,
  scrollToErr = false,
  setScrollToErr = defaultFn,
  id,
  shareableFormParams,
  isPrelogin = false,
  preLoginToken,
  allValues = [],
}) => {
  const Info: any = InfoComponent;
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [regions, setRegions] = useState<Array<InputOptionsType> | null>([]);

  const [getRegions] = useLazyGetRegionByCountryQuery();

  const getListOptions = (options: Array<InputOptionsType> | null) => options?.map((item) => item.value) || [];
  const getRegionOptions = (options: Array<InputOptionsType> | null) =>
    options?.map((item) => ({
      label: item.value,
      value: item.id,
      isDisabled: item.is_disabled,
    })) || [];

  const getOptions = (field: FieldType) => {
    let formattedOptions: Array<MenuItem> = [];

    const { options, depends_on } = field;

    if (options) {
      formattedOptions =
        options?.map((item: InputOptionsType) => ({
          label: item?.value,
          value: item?.id,
          icon: item?.logo ? (
            <Image src={item.logo} width={24} height={24} alt='icon' className='tw-mr-4' priority />
          ) : null,
          isDisabled: item?.is_disabled,
          iconCategory: item?.icon_category,
          iconId: item?.icon_id,
          info: item?.info,
        })) || [];
    } else if (depends_on) {
      const depandingField: FieldType | undefined = formFields.form_fields.find(
        (item: FieldType) => item.key === depends_on
      );

      if (depandingField) {
        const { conditional_values, key } = depandingField;
        const formStateValue = formState[key]?.value;

        if (conditional_values && formStateValue) {
          const fieldOptions: Array<InputOptionsType> = conditional_values[formStateValue] || [];

          formattedOptions =
            fieldOptions?.map((item: InputOptionsType) => ({
              label: item.value,
              value: item.value,
              icon: item.logo ? (
                <Image src={item.logo} width={24} height={24} alt='icon' className='tw-mr-4' priority />
              ) : null,
              isDisabled: item.is_disabled,
            })) || [];
        }
      }
    }

    return formattedOptions;
  };

  const getError = (field: FieldType) => {
    const fieldValue = formState[field.key];

    if (field.is_required && (!fieldValue || (Array.isArray(fieldValue) && fieldValue.length === 0)))
      return `*${field.display_name} is required`;

    return field.error_message ? field.error_message : `${field.display_name} is invalid`;
  };

  const regionFetch = async () => {
    try {
      const regions = await getRegions(selectedCountry).unwrap();

      setRegions(regions);
    } catch (e) {
      console.log(e);
    }
  };

  const getDisplayName = (field: FieldType) => `${field.display_name}${!field.is_required ? ' (Optional)' : ''}`;

  const handleCheckboxClick = (field: FieldType) => {
    const value = formState[field.key] === 'true' || formState[field.key] === true;
    const updatedValue = `${!value}`;

    updateFormState(field.key, updatedValue);
  };

  const handleDateChange = (field: FieldType, value: string) => {
    const dateValue = new Date(value);
    const currentDate = new Date();
    const compareDates = compareAsc(dateValue, currentDate);

    if (compareDates !== 1) updateFormState(field.key, value);
  };

  const handleDropdownChange = (item: any, fieldKey: string) => {
    const keyParts = fieldKey.split('.');

    if (keyParts?.[0] === COUNTRY_KEY) {
      setSelectedCountry(item?.label?.toString());
      updateFormState(STATE_KEY, null);
    } else if (keyParts?.[1] === COUNTRY_KEY) {
      setSelectedCountry(item?.label?.toString());
      updateFormState(`${keyParts?.[0]}.${STATE_KEY}`, null);
    }
    updateFormState(fieldKey, item);
  };

  const getComponent = (field: FieldType) => {
    const { wrapperClass, titleClass, size, descriptionClass, errClassName } = customInputText;
    const { labelOverrideClassName, containerClass } = customCheckbox;

    let labelProps;
    const errorText = errors[field.key] ? getError(field) : '';
    const error = !!errorText;
    let checkBoxLabel;

    switch (field.type) {
      case FormFieldType.TEXT:
        labelProps = useDescriptionLabel
          ? {
              description: field.tooltip ? (
                <LabelWithTooltip label={getDisplayName(field)} tooltip={field?.tooltip} />
              ) : (
                getDisplayName(field)
              ),
              descriptionClass: descriptionClass,
            }
          : {
              title: field.tooltip ? (
                <LabelWithTooltip label={getDisplayName(field)} tooltip={field?.tooltip} />
              ) : (
                getDisplayName(field)
              ),
              titleClass: titleClass,
            };

        return isDestinyComponent ? (
          <DestinyInput
            wrapperClass={wrapperClass}
            showLabel={true}
            labelProps={labelProps}
            showSupporterInfo={error}
            supporterInfoProps={{
              text: errorText,
              type: SUPPORT_INFO_TYPES.ERROR,
              textClass: 'f-12-300 tw-text-RED_PRIMARY tw-mb-3',
            }}
            inputFieldProps={{
              size,
              inputTagProps: {
                id: field.key.includes('.') ? field.key.replace('.', '-') : field.key,
                value: formState[field.key] || '',
                onChange: (evt) => updateFormState(field.key, evt.target.value),
                error: errorText,
                placeHolder: field.place_holder,
                eventId: `${id}_${field?.key?.toUpperCase()}_TEXT_INPUT`,
                eventCallback: trackMixpanel,
                disabled: field?.is_disabled,
              },
            }}
          />
        ) : (
          <Input
            id={field.key.includes('.') ? field.key.replace('.', '-') : field.key}
            tooltip={field.tooltip}
            label={getDisplayName(field)}
            value={formState[field.key] || ''}
            onChange={(evt) => updateFormState(field.key, evt.target.value)}
            error={errors[field.key] ? getError(field) : ''}
            placeHolder={field.place_holder}
            onClear={() => updateFormState(field.key, '')}
            className={`${defaultStyle ? 'f-16-400 bg-inherit tw-text-white tw-placeholder-GRAY_3' : ''}`}
            customInputClassName={customInput}
          />
        );
      case FormFieldType.DATE_PICKER:
        return (
          <Input
            type='date'
            id={field.key}
            tooltip={field.tooltip}
            label={getDisplayName(field)}
            value={formState[field.key] || ''}
            onChange={(evt) => handleDateChange(field, evt.target.value)}
            error={errors[field.key] ? getError(field) : ''}
            placeHolder={field.place_holder}
            onClear={() => updateFormState(field.key, '')}
            className={`${defaultStyle ? 'f-16-400 bg-inherit tw-text-white tw-placeholder-GRAY_3' : ''}`}
            customInputClassName={customInput}
            max={format(new Date(), DATE_FORMATS.YYYYMMDD)}
            labelClassName={useDescriptionLabel ? 'f-12-300 tw-text-TEXT_SECONDARY tw-mb-1' : ''}
            errClassName={errClassName}
          />
        );
      case FormFieldType.NUMBER:
        return isDestinyComponent ? (
          <DestinyInput
            wrapperClass={wrapperClass}
            showLabel={true}
            labelProps={{ title: getDisplayName(field), titleClass }}
            inputFieldProps={{
              size,
              inputTagProps: {
                type: 'number',
                id: field.key,
                value: formState[field.key] || '',
                onChange: (evt) => updateFormState(field.key, evt.target.value, field.type),
                error: errors[field.key] ? getError(field) : '',
                placeHolder: field.place_holder,
                eventId: `${id}_${field?.key?.toUpperCase()}_NUMBER_INPUT`,
                eventCallback: trackMixpanel,
              },
            }}
          />
        ) : (
          <Input
            type='number'
            id={field.key}
            tooltip={field.tooltip}
            label={getDisplayName(field)}
            value={formState[field.key] || ''}
            onChange={(evt) => updateFormState(field.key, evt.target.value)}
            error={errors[field.key] ? getError(field) : ''}
            placeHolder={field.place_holder}
            onClear={() => updateFormState(field.key, '')}
            className={`${defaultStyle ? 'f-16-400 bg-inherit tw-text-white tw-placeholder-GRAY_3' : ''}`}
            customInputClassName={customInput}
          />
        );

      case FormFieldType.SELECT:
        labelProps = useDescriptionLabel
          ? { description: getDisplayName(field), descriptionClass: descriptionClass }
          : { title: getDisplayName(field), titleClass: titleClass };

        if (field?.key?.split('.')?.includes(COUNTRY_KEY) && !selectedCountry && formState[field.key])
          setSelectedCountry(formState[field.key]?.label);

        return (
          <>
            {isDestinyComponent ? (
              <>
                <DestinyDrop
                  onChange={(item) => handleDropdownChange(item, field.key)}
                  showLabel
                  labelProps={labelProps}
                  options={getOptions(field)}
                  dropdownIndicator={
                    <Image src={CHEVRON_DOWN} alt='dropdownIndicator' height={16} width={16} priority />
                  }
                  error={error}
                  placeholder={field.place_holder}
                  selectedIcon={
                    <Image
                      src={SELECTED_GREEN}
                      alt='selected'
                      height={16}
                      width={16}
                      className='tw-ml-auto tw-mr-4'
                      priority
                    />
                  }
                  showSupporterInfo={error}
                  supporterInfoProps={{
                    type: SUPPORT_INFO_TYPES.ERROR,
                    text: errorText,
                    textClass: 'f-12-300 tw-text-RED_PRIMARY tw-mb-3',
                  }}
                  {...customDestinyDropdown}
                  id={`${id}_${field?.key?.toUpperCase()}_DROPDOWN`}
                  eventCallback={trackMixpanel}
                  defaultValue={formState[field.key]}
                  isDisabled={field?.is_disabled}
                />
                {!!formState[field.key]?.info && (
                  <SupporterInfo
                    text={formState[field.key]?.info}
                    iconAttributes={{
                      iconCategory: ICON_SPRITE_TYPES.TIME,
                      id: 'clock',
                      width: 12,
                      height: 12,
                    }}
                    wrapperClass='tw-flex tw-items-center tw-mb-8'
                    textClass='f-12-300 tw-text-TEXT_SECONDARY tw-whitespace-pre'
                  />
                )}
              </>
            ) : (
              <Dropdown
                id={field.key.includes('.') ? field.key.replace('.', '-') : field.key}
                selectLabel='Select'
                tooltip={isDestinyComponent ? undefined : field.tooltip}
                label={isDestinyComponent ? undefined : getDisplayName(field)}
                selectedItem={formState[field.key]}
                options={getOptions(field)}
                onMenuItemClick={(item) => handleDropdownChange(item, field.key)}
                hasError={errors[field.key] ? getError(field) : ''}
                customDropdown={customDropdown}
              />
            )}
          </>
        );

      case FormFieldType.REGION_PICKER:
        labelProps = useDescriptionLabel
          ? { description: getDisplayName(field), descriptionClass: descriptionClass }
          : { title: getDisplayName(field), titleClass: titleClass };

        return (
          <>
            {isDestinyComponent ? (
              <DestinyDrop
                onChange={(item) => updateFormState(field.key, item)}
                showLabel
                labelProps={labelProps}
                options={getRegionOptions(regions)}
                dropdownIndicator={<Image src={CHEVRON_DOWN} alt='dropdownIndicator' height={16} width={16} priority />}
                error={error}
                placeholder={field.place_holder}
                selectedIcon={
                  <Image
                    src={SELECTED_GREEN}
                    alt='selected'
                    height={16}
                    width={16}
                    className='tw-ml-auto tw-mr-4'
                    priority
                  />
                }
                showSupporterInfo={error}
                supporterInfoProps={{
                  type: SUPPORT_INFO_TYPES.ERROR,
                  text: errorText,
                  textClass: 'f-12-300 tw-text-RED_PRIMARY tw-mb-3',
                }}
                {...customDestinyDropdown}
                id={`${id}_${field?.key?.toUpperCase()}_DROPDOWN`}
                eventCallback={trackMixpanel}
                isDisabled={!regions?.length || field?.is_disabled}
                defaultValue={formState[field.key]}
              />
            ) : (
              <Dropdown
                id={field.key.includes('.') ? field.key.replace('.', '-') : field.key}
                selectLabel='Select'
                tooltip={field.tooltip}
                label={getDisplayName(field)}
                selectedItem={formState[field.key]}
                options={getRegionOptions(regions)}
                onMenuItemClick={(item) => {
                  updateFormState(field.key, item);
                }}
                hasError={errors[field.key] ? getError(field) : ''}
                customDropdown={customDropdown}
              />
            )}
          </>
        );
      case FormFieldType.FILE:
        return (
          <FileUploaderWrapper
            Component={FileUploader}
            title={field.display_name}
            footer='Supported files: PDF, JPG, JPEG, PNG, BMP max 20MB.'
            filesSelected={formState[field.key]}
            errorMsg={errors[field.key] ? getError(field) : ''}
            onFilesSelect={(file: string | null) => updateFormState(field.key, file)}
            disableNext={(value: boolean) => updateFormState(DISABLE_NEXT_KEY, value)}
            acceptedFormats={`${INPUT_FILE_FORMATS.PNG}, ${INPUT_FILE_FORMATS.JPEG}, ${INPUT_FILE_FORMATS.JPG}, ${INPUT_FILE_FORMATS.PDF}, ${INPUT_FILE_FORMATS.BMP}`}
            isPrelogin={isPrelogin}
            preLoginToken={preLoginToken}
            id={field.key}
            description={field.description}
          />
        );

      case FormFieldType.SLIDER:
        return (
          <Slider
            label={getDisplayName(field)}
            tooltip={field.tooltip}
            current={formState[field.key]}
            min={field.min_value}
            max={field.max_value}
            onChange={(value) => updateFormState(field.key, value)}
            id={field.key}
          />
        );

      case FormFieldType.URL:
        return (
          <Input
            id={field.key}
            tooltip={field.tooltip}
            label={getDisplayName(field)}
            value={formState[field.key] || ''}
            onChange={(evt) => updateFormState(field.key, evt.target.value)}
            error={errors[field.key] ? getError(field) : ''}
            placeHolder={field.place_holder || 'https://'}
            onClear={() => updateFormState(field.key, '')}
            className={`${defaultStyle ? 'f-16-400 bg-inherit tw-text-white tw-placeholder-GRAY_3' : ''}`}
            customInputClassName={customInput}
          />
        );

      case FormFieldType.HEADING:
        return <Section title={field.display_name} description={field.description} tooltip={field.tooltip} />;

      case FormFieldType.UNORDERED_LIST:
        return <UnorderedList title={field.display_name} list={getListOptions(field.options)} />;
      case FormFieldType.TEXT_AREA:
        return (
          <>
            {isDestinyComponent ? (
              <Label title={getDisplayName(field)} showTitle />
            ) : (
              <FormToolTip label={getDisplayName(field)} />
            )}
            <Textarea
              id={field.key}
              placeHolder={field.place_holder}
              className={`f-16-300 ${isDestinyComponent ? 'tw-mb-2 tw-mt-2.5' : ''}`}
              value={formState[field.key] || ''}
              onChange={(evt) => updateFormState(field.key, evt.target.value)}
              error={errors[field.key] ? getError(field) : ''}
              showSupporterInfo={error}
              supporterInfoProps={{
                type: SUPPORT_INFO_TYPES.ERROR,
                text: errorText,
                textClass: isDestinyComponent ? undefined : 'tw-text-sm tw-text-ERROR_RED',
              }}
              errBorderClassName={isDestinyComponent ? 'tw-border-RED_SECONDARY' : ''}
            />
          </>
        );
      case FormFieldType.MULTI_SELECT:
        return (
          <>
            {!isDestinyComponent && (
              <FormToolTip label={getDisplayName(field)} id={`multi-select-dropdown-${field.key}`} />
            )}
            <DestinyDrop
              onChange={(item) => updateFormState(field.key, item)}
              showLabel={isDestinyComponent}
              labelProps={{ showTitle: true, title: getDisplayName(field) }}
              options={getOptions(field)}
              dropdownIndicator={<Image src={CHEVRON_DOWN} alt='dropdownIndicator' height={16} width={16} priority />}
              closeIcon={<Image src={CROSS_GRAY} alt='closeIcon' height={10} width={10} className='tw-ml-2' priority />}
              error={error}
              placeholder={field.place_holder}
              isMulti
              showSupporterInfo={error}
              supporterInfoProps={{
                type: SUPPORT_INFO_TYPES.ERROR,
                text: errorText,
                textClass: isDestinyComponent ? undefined : 'tw-text-sm tw-text-ERROR_RED',
              }}
              errorColor={isDestinyComponent ? '#D64141' : '#EA3323'}
              selectFieldWrapperClass={isDestinyComponent ? 'tw-w-full tw-my-3' : 'tw-w-full tw-mb-1'}
              defaultValue={formState[field.key]}
              controlled
              value={formState[field.key]}
              id={`${id}_${field?.key?.toUpperCase()}_DROPDOWN`}
              eventCallback={trackMixpanel}
            />
          </>
        );
      case FormFieldType.PHONE_NUMBER:
        return (
          <PhoneNumber
            value={formState[field.key]}
            onChange={(value) => updateFormState(field.key, value)}
            error={errorText}
            labelClassName={
              useDescriptionLabel
                ? 'f-12-300 tw-text-TEXT_SECONDARY !tw-mb-1'
                : 'f-16-400 !tw-mb-0.5 tw-text-TEXT_GRAY_2'
            }
            inputWrapperClassName={useDescriptionLabel ? 'tw-mt-[14px]' : 'tw-mt-[18px]'}
            isDisabled={field?.is_disabled}
            isFormComponent
          />
        );
      case FormFieldType.CHECKBOX:
        checkBoxLabel = field?.tooltip ? (
          <LabelWithTooltip label={getDisplayName(field)} tooltip={field?.tooltip} />
        ) : (
          getDisplayName(field)
        );

        return (
          <MenuOption
            eventCallback={trackMixpanel}
            innerProps={{
              id: `${id}_${(field.key.includes('.')
                ? field.key.replace('.', '-')
                : field.key
              )?.toUpperCase()}_CHECKBOX`,
            }}
            isSelected={formState[field.key] === 'true' || formState[field.key] === true}
            onClick={() => handleCheckboxClick(field)}
            isMulti
            label={checkBoxLabel}
            containerClass={containerClass}
            wrapperClass='tw-flex tw-items-center'
            labelOverrideClassName={labelOverrideClassName}
            checkboxClassName='tw-h-[18px] !tw-pr-4'
            checkboxDisplayContainerClassName='!tw-top-0 !tw-left-0'
            contentWrapper=''
          />
        );
      case FormFieldType.TABS:
        return (
          <TabsWithIcons
            list={getOptions(field)}
            onChange={(item) => updateFormState(field.key, item)}
            customSelectedIndex={
              getOptions(field)?.findIndex((e: MenuItem) => e?.value === formState[field.key]?.value) || 0
            }
            id={`${id}_${field?.key?.toUpperCase()}`}
          />
        );
      case FormFieldType.BORDERLESS_TEXT:
        return (
          <BorderlessInput
            wrapperClass={wrapperClass}
            error={error}
            errorText={errorText}
            id={`${id}_${field?.key?.toUpperCase()}`}
            onChange={(value) => updateFormState(field.key, value)}
            size={size}
            value={formState[field.key] || ''}
            placeHolder={field.place_holder}
          />
        );
      case FormFieldType.HIDDEN:
        return null;
      default:
        break;
    }
  };

  useEffect(() => {
    if (selectedCountry) regionFetch();
  }, [selectedCountry]);

  const formRef = useRef<HTMLDivElement>(null);

  const getIndexOfErrorElement = () => {
    let errKey = '';

    for (const [key, value] of Object.entries(errors)) {
      if (value) {
        errKey = key;
        break;
      }
    }
    let index = 0;

    if (errKey) index = formFields?.form_fields?.findIndex((item: any) => item?.key === errKey);

    return index;
  };

  useEffect(() => {
    if (scrollToErr && Object.keys(errors).some((key: any) => errors[key])) {
      const index = getIndexOfErrorElement();

      formRef?.current?.children?.[index]?.scrollIntoView({ block: 'center', inline: 'start', behavior: 'smooth' });
      setScrollToErr(false);
    }
  }, [errors]);

  useEffect(() => {
    formFields?.form_fields?.forEach((currentField: FieldType) => {
      if (!checkActiveIfValid(currentField, formState, allValues) && formState[currentField.key])
        updateFormState(currentField.key, '');
    });
  }, [formFields, formState]);

  return (
    <div className={wrapperClass} ref={formRef}>
      {formFields?.form_fields?.map((field: FieldType, index: number) =>
        checkActiveIfValid(field, formState, allValues) ? (
          <div key={`${field.key}_${index}`}>
            {field.type === FormFieldType.INFO && Info ? <Info data={field} /> : getComponent(field)}
            {field.allow_link_generation && !!shareableFormParams && (
              <div className='tw-mt-2'>
                Or
                <GenerateShareableForm
                  fieldKey={field.key}
                  apiParams={shareableFormParams}
                  onLinkGenerated={(key: string, id: string, link: string) => {
                    updateFormState(key + SHAREABLE_FORM_LINK_SUFFIX, link);
                    updateFormState(key + SHAREABLE_FORM_ID_SUFFIX, id);
                  }}
                  value={formState[field.key + SHAREABLE_FORM_LINK_SUFFIX]}
                  onEmailChange={(email) => updateFormState(field.key + SHAREABLE_FORM_EMAIL_SUFFIX, email)}
                  email={formState[field.key + SHAREABLE_FORM_EMAIL_SUFFIX]}
                />
              </div>
            )}
          </div>
        ) : null
      )}
    </div>
  );
};

export default Form;
