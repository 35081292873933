import { useCreateShareableFormMutation } from 'api/shareableForm';
import { SESSION_CAPABILITY_CONTEXT_KEYS } from 'constants/index';
import { useSessionToken } from 'hooks/useSessionToken';
import { CreateShareableFormFlowParams, FIELD_TYPES, SHAREABLE_FORM_TYPES } from 'types/shareableForm.types';

interface useGenerateShareableFormParams {
  onLinkGenerated: (key: string, id: string, link: string) => void;
  apiParams: CreateShareableFormFlowParams;
  key: string;
}
interface useGenerateShareableFormHook {
  isLoading: boolean;
  isError: boolean;
  error: any;
  generateLink: (key: string) => void;
}

const useGenerateShareableForm: (params: useGenerateShareableFormParams) => useGenerateShareableFormHook = ({
  onLinkGenerated,
  apiParams,
  key,
}) => {
  const [createShareableForm, { isLoading, isError, error }] = useCreateShareableFormMutation({});
  const { sessionToken: createLinkSessionToken, createSessionToken } = useSessionToken(
    SESSION_CAPABILITY_CONTEXT_KEYS.SHAREABLE_FORM_CREATE
  );

  const getFieldsForKey = () => {
    switch (key) {
      case SHAREABLE_FORM_TYPES.SSN:
        return [FIELD_TYPES.SSN, FIELD_TYPES.TAX_IDENTIFICATION_DOCUMENT];
      default:
        return [];
    }
  };

  const handleGenerateLink = (email: string) => {
    key &&
      createShareableForm({
        idempotencyHeader: createLinkSessionToken ?? '',
        data: { fields: getFieldsForKey(), receiver_email: email, ...apiParams },
      })
        .unwrap()
        .then(({ form_url, form_id }) => {
          onLinkGenerated(key, form_id, form_url);
        })
        .finally(() => createSessionToken());
  };

  return {
    isLoading,
    isError,
    error,
    generateLink: handleGenerateLink,
  };
};

export default useGenerateShareableForm;
