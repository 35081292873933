import { type FC } from 'react';
import Input from 'destiny/dist/components/organisms/input';
import { SUPPORT_INFO_TYPES } from 'destiny/dist/constants';
import { trackMixpanel } from 'utils/mixpanel';

type BorderlessInputProps = {
  wrapperClass?: string;
  error?: boolean;
  errorText?: string;
  id: string;
  value: string;
  onChange: (value: string) => void;
  size?: 'LARGE' | 'MEDIUM' | 'SMALL' | 'SMALLEST';
  placeHolder?: string;
};

const BorderlessInput: FC<BorderlessInputProps> = ({
  wrapperClass,
  error,
  errorText,
  value,
  id,
  onChange,
  size,
  placeHolder,
}) => (
  <Input
    wrapperClass={wrapperClass}
    showLabel={false}
    showSupporterInfo={error}
    supporterInfoProps={{
      text: errorText,
      type: SUPPORT_INFO_TYPES.ERROR,
      textClass: 'f-12-300 tw-text-RED_PRIMARY tw-my-3',
    }}
    inputFieldProps={{
      size,
      inputTagProps: {
        id: id,
        value: value,
        onChange: (e) => onChange(e.target.value),
        error: errorText,
        eventId: `${id}_TEXT_INPUT`,
        eventCallback: trackMixpanel,
        placeHolder,
        inputFontClassName: 'f-18-500',
        inputSizeClass: 'tw-h-[26px]',
        inputClass:
          'tw-w-full tw-text-TEXT_PRIMARY placeholder:tw-text-TEXT_TERTIARY placeholder:tw-font-light tw-border-DIVIDER_GRAY focus:tw-border-ZAMP_PRIMARY focus:tw-outline-0',
        name: id,
        autoFocus: true,
      },
    }}
    inputFieldWrapperClass=''
  />
);

export default BorderlessInput;
