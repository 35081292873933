import { ChangeEvent, type FC } from 'react';
import { ORIENTATION_ENUMS } from 'constants/index';
import { SupporterInfo } from 'destiny/dist/components/molecules/supporterInfo';
import Input from 'destiny/dist/components/organisms/input';
import { SUPPORT_INFO_TYPES } from 'destiny/dist/constants';
import { OptionsType } from 'destiny/dist/types/dropdown';
import { COUNTRY_OPTIONS } from 'components/country-picker/countries';
import CountryPicker from 'components/country-picker/CountryPicker';

type PhoneNumberValueType = { dial_code: string; phone: string };
type PhoneNumberProps = {
  value: PhoneNumberValueType;
  onChange: (value: string) => void;
  error?: string;
  labelClassName?: string;
  inputWrapperClassName?: string;
  isDisabled?: boolean;
  isFormComponent?: boolean;
};

const PhoneNumber: FC<PhoneNumberProps> = ({
  value,
  onChange,
  error = '',
  labelClassName = 'f-16-400 !tw-mb-2 tw-text-TEXT_GRAY_2',
  inputWrapperClassName = 'tw-mt-[18px]',
  isDisabled = false,
  isFormComponent = false,
}) => {
  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const localValue = typeof value === 'string' ? JSON.parse(value) : value;
    const updatedValue = JSON.stringify({ ...localValue, phone: evt.target.value });

    onChange(updatedValue);
  };

  const handleDialCodeChange = (phoneRegion: OptionsType[]) => {
    const localValue = typeof value === 'string' ? JSON.parse(value) : value;

    const updatedValue = JSON.stringify({ ...localValue, dial_code: `${phoneRegion[0]?.value}` });

    onChange(updatedValue);
  };

  const localValue = typeof value === 'string' ? JSON.parse(value) : value;

  const selectedCountry = COUNTRY_OPTIONS.find((option) => option?.dial_code === localValue?.dial_code);

  const selectedDialCode: OptionsType | undefined = selectedCountry
    ? {
        label: `${selectedCountry?.name}${' (' + selectedCountry?.dial_code + ')'}`,
        value: `${selectedCountry?.dial_code}`,
        icon: <span className='tw-mr-2'>{selectedCountry?.emoji}</span>,
        metadata: selectedCountry,
      }
    : undefined;

  return (
    <>
      <div className='tw-flex tw-gap-4 tw-mb-2'>
        <CountryPicker
          label='Phone No.'
          id='phone'
          labelClassName={labelClassName}
          selectedValueKey='dial_code'
          onMenuItemClick={handleDialCodeChange}
          defaultValue={selectedDialCode}
          orientation={ORIENTATION_ENUMS.HORIZONTAL}
          customControlStyles={{
            padding: '0px 24px',
            minHeight: '44px',
            height: '44px',
          }}
          error={error}
          hideSupporterInfo
          isDisabled={isDisabled}
          isFormComponent={isFormComponent}
        />
        <div className={`tw-grow ${inputWrapperClassName}`}>
          <Input
            inputFieldProps={{
              inputTagProps: {
                placeHolder: 'Phone number',
                name: 'phone',
                value: localValue?.phone || '',

                onChange: handleChange,
                error: error,
                id: 'SIGNUP_PHONE_INPUT',
                disabled: isDisabled,
              },
              size: 'SMALL',
            }}
          />
        </div>
      </div>
      {!!error && <SupporterInfo text={error} type={SUPPORT_INFO_TYPES.ERROR} />}
    </>
  );
};

export default PhoneNumber;
